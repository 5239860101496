import Carousel from "../Carousel/Carousel";
import "./Promo.css"
export default function Promo() {
  return (
    <section className="promo page__promo">

      <Carousel />
      
    </section>
  );
}
