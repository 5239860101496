import prof1 from "../../img/prof1.jpeg";
import prof3 from "../../img/prof3.jpeg";
import prof5 from "../../img/kalitka.jpeg";
import prof6 from "../../img/vorotaRas.jpeg";
import prof7 from "../../img/vorotaRaz.jpeg";
import montageProf from "../../img/shemaProf.png";import montageEvro from "../../img/evroShema.jpg";
import main from "../../img/profMain.jpg";
import prof9 from "../../img/prof9.jpeg";
import plus from "../../img/plus.png";
import main2 from "../../img/main2.jpg";
import main3 from "../../img/main3.jpg";
import main4 from "../../img/main4.jpg";
import main5 from "../../img/main5.jpg";
import montageRabitca from "../../img/shemaRabica.jpeg";
import montage3d from "../../img/shema3d.jpeg";
import montageWood from "../../img/shemaWood.jpeg";
import im1 from "../../img/prof4.jpeg";
import im2 from "../../img/prof5.jpeg";
import im3 from "../../img/prof6.jpeg";
import im4 from "../../img/prof7.jpeg";
import im5 from "../../img/prof8.jpeg";
import im6 from "../../img/prof9.jpeg";
import rb1 from "../../img/rabica1.jpeg";
import rb2 from "../../img/rabica2.jpeg";
import rb3 from "../../img/rabica3.jpeg";
import rb4 from "../../img/rabica4.jpeg";
import evro1 from "../../img/evro1.jpeg";
import evro2 from "../../img/evro2.jpeg";
import evro3 from "../../img/evro3.jpeg";
import evro4 from "../../img/evro4.jpeg";
import evro5 from "../../img/evro6.jpeg";
import evro6 from "../../img/evro7.jpeg";
import wd1 from "../../img/wd1.jpeg";
import wd2 from "../../img/wd2.jpeg";
import wd3 from "../../img/wd3.jpeg";
import wd4 from "../../img/wd4.jpeg";
import wd5 from "../../img/wd5.jpeg";
import ks1 from "../../img/ks1.jpeg";
import ks2 from "../../img/ks2.jpeg";
import ks3 from "../../img/ks3.jpeg";
import ks4 from "../../img/ks4.jpeg";
import ks5 from "../../img/ks5.jpeg";
import s3d1 from "../../img/3d1.jpg";
import s3d2 from "../../img/3d2.jpg";
import s3d3 from "../../img/3d3.jpeg";
import s3d4 from "../../img/3d4.jpeg";
import s3d5 from "../../img/3d5.jpeg";
import s3d6 from "../../img/3d6.jpg";


const zaborImages = [
  {
    alt: "Забор из профлиста под ключ",
    text: "Забор из профлиста под ключ",
    way: "/proflist",
  },
  {
    alt: "Забор из сетки рабицы под ключ",
    text: "Забор из сетки рабицы под ключ",
    way: "/grid",
  },
  {
    alt: "Забор из 3D сетки под ключ",
    text: "Забор из 3D сетки под ключ",
    way: "/3d",
  },
  {
    alt: "Забор из деревянного штакетника под ключ",
    text: "Забор из деревянного штакетника под ключ",
    way: "/wood",
  },
  {
    alt: "Забор из евроштакетника металлического под ключ",
    text: "Забор из евроштакетника металлического под ключ",
    way: "/evroiron",
  },
  {
    alt: "Каркас забора под ключ",
    text: "Каркас забора под ключ",
    way: "/base",
  },
  {
    alt: "Забор ранчо и жалюзи под ключ",
    text: "Забор ранчо и жалюзи под ключ",
    way: "/rancho",
  },

  {
    alt: "Ворота откатные",
    text: "Ворота откатные под ключ",
    way: "/vorota",
  },
];

const proflist = {
  title: "Забор из профлиста под ключ",
  image: main,
  alt: "Забор из профлиста под ключ",
  subtitle: "В стоимость включен: весь материал, монтаж.",
  text: "Профнастил является самым популярным материалом для создания ограждений. Покупателей привлекает не только стильное исполнение конструкции, но и высокое качество, долговечность, простой уход, доступная стоимость и другие преимущества. Для создания забора используют листы профнастила с цветным полимерным покрытием. Оно защищает материал от коррозии и позволяет подобрать листы, соответствующие общей концепции дизайна территории. Мы используем в работе Профнастил С8/С20 (0,4 мм)",
  titlePlus: "Преимущества",
  montageTitle: "Монтаж забора из профнастила",
  montageText:
    "Чтобы правильно установить забор нужно действовать по отработанной схеме. Все элементы полностью готовы к установке и поставляются в соответствующем размере. Мастера нашей компании выполняют монтажные работы по установке забора из профнастила за 1 день в Барнауле и на территории Алтайского края.",
  montageImg: montageProf,
  altShema: "схема установки забора",

  advan: [
    {
      image: plus,
      text: "Плюсы: Большой ассортимент расцветок, толщины, текстуры",
    },
    {
      image: plus,
      text: "Быстрый монтаж и возможность проводить технический ремонт",
    },
    {
      image: plus,
      text: "Доступная цена и срок эксплуатации более 25 лет",
    },
    {
      image: plus,
      text: "Эстетичность, высокие эксплуатационные свойства",
    },
  ],
  //данные для таблицы забора

  fenceData: {
    title: "ЗАБОР ПРОФНАСТИЛ - СТАНДАРТ",
    rows: [
      {
        components: [
          "Столбы (полимерные) 60*60*2400 мм (толщина металла 2 мм;)",
          "Заглушка 60*60 ПВХ черная;",
          "Прожилины (полимерные) 40*20 мм (толщина металла 1,5 мм) – 2 шт.;",
          "Профнастил С8/С20 (0,4 мм) с саморезами;",
        ],
        height: "1,5 м.",
        frame: "ПОЛИМЕР",
        price: "от 3500р./м.п.",
      },
      {
        components: [
          "Столбы (полимерные) 60*60*3000 мм.;",
          "Заглушка 60*60 ПВХ черная;",
          "Прожилины (полимерные) 40*20 мм (толщина металла 1,5 мм) – 2 шт.;",
          "Профнастил С8/С20 (0,4 мм) с саморезами;",
        ],
        height: "2,0 м.",
        frame: "ПОЛИМЕР",
        price: "от 3800 р./м.п.",
      },
    ],
    additionalOptions: [
      "J-рейка 20*40*2000 мм (закрывает верхний торец) 300 р /м.п",
      "Бордюрный фундамент от 1700 р/м.п. (высота 20 см, ширина 8 см)",
      "Ленточный фундамент от 2500 р/м.п.  (высота 10 см, ширина 20 см)",
      "Демонтаж старого забора 200 р/м.п.",
      "Двусторонний окрас профлиста +350 р/м.п.",
    ],
  },
  //варианты заборов во вкладке профлист

  variants: {
    title: "Виды забора из профнастила",
    cards: [
      {
        image: prof1,
        alt: "Забор с кирпичными столбами",
      },
      {
        image: prof3,
        alt: "Забор на ленточном фундаменте",
      },
      {
        image: prof9,
        alt: "Облегченный забор",
      },
      {
        image: prof5,
        alt: "калитка",
      },
      {
        image: prof6,
        alt: "Ворота распашные",
      },
      {
        image: prof7,
        alt: "Ворота откатные",
      },
    ],
  },

  //данные для таблицы калитка

  kalitkaData: {
    title: "Входная группа КАЛИТКА распашная ширина 1,0 м",
    rows: [
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "профлист С8 - 1 лист.",
        ],
        height: "1,5 м.",
        frame: "ПОЛИМЕР",
        price: "от 8000 р./м.п.",
      },
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "профлист С8 - 1 лист.",
        ],
        height: "2,0 м.",
        frame: "ПОЛИМЕР",
        price: "от 8000 р./м.п.",
      },
    ],
  },

  //данные для таблицы ворота

  vorotaData: {
    title: "Входная группа ВОРОТА распашные",
    rows: [
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
          "Прфнастил С8 (3,0 м. - 3 листа; 3,5 м. - 3 листа; 4,0 м. - 4 листа)",
        ],
        height: "1,5 м.",
        frame: "ПОЛИМЕР",
        price: "от 12000 р./м.п.",
      },
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
          "Прфнастил С8 (3,0 м. - 3 листа; 3,5 м. - 3 листа; 4,0 м. - 4 листа)",
        ],
        height: "2,0 м.",
        frame: "ПОЛИМЕР",
        price: "от 12000 р./м.п.",
      },
    ],
  },
// фото во вкладках виды заборов
slideImages : [
  {
    image: im1,
   alt: "изображение наших работ забора из профлиста"
  },
  {
    image: im2,
    alt: "изображение наших работ забора из профлиста"

  },
  {
    image: im3,
    alt: "изображение наших работ забора из профлиста"

  },
  {
    image: im4,
    alt: "изображение наших работ забора из профлиста"

  },
  {
    image: im5,
    alt: "изображение наших работ забора из профлиста"

  },
  {
    image: im6,
    alt: "изображение наших работ забора из профлиста"

  },
],

};

const rabitsa = {
  title: "Забор из сетки рабицы под ключ",
  image: main2,
  alt: "Забор из сетки рабицы под ключ",
  subtitle: "В стоимость включен: весь материал, монтаж.",
  text: "Забор из сетки-рабицы на сегодняшний день один из самых экономичных видов ограждения. Данный вид забора приобрел свою популярность в основном для ограждения участков в садовых товариществах. Также забор из сетки-рабицы обозначает границы участка и препятствует проникновению посторонних - Сетка-рабица - это материал представляет собой полотно, которое состоит из спиралей, проволочного типа. Они крепко сплетены между собой. Материал характеризуется большим ассортиментом размера ячеек от 20 до 100 мм. По высоте рулон бывает от 1 до 2 м.",
  titlePlus: "Преимущества",
  montageTitle: "Монтаж забора из сетки рабицы",
  montageText:
    "Чтобы правильно установить забор нужно действовать по отработанной схеме. Все элементы полностью готовы к установке и поставляются в соответствующем размере. Мастера нашей компании выполняют монтажные работы по установке забора из сетки рабицы за 1 день в Барнауле и на территории Алтайского края.",
  montageImg: montageRabitca,
  altShema: "схема установки забора",
  advan: [
    {
      image: plus,
      text: "Плюсы: Высокая прочность, а также устойчивость к внешнему воздействию и механическим повреждениям",
    },
    {
      image: plus,
      text: "Свободно пропускает свет, не затеняет участок",
    },
    {
      image: plus,
      text: "Выдерживает повышенную нагрузку",
    },
    {
      image: plus,
      text: "Неприхотливость в уходе",
    },
    {
      image: plus,
      text: "Длительный срок эксплуатации",
    },
    {
      image: plus,
      text: "Возможность декоративного оформления",
    },
  ],
  fenceData: {
    title: "ЗАБОР СЕТКА РАБИЦА - СТАНДАРТ",
    rows: [
      {
        components: [
          "столбы (стальные) 40*40*2400мм;",
          "заглушка 40*40 ПВХ черная;",
          "сетка Рабица (сталь) ячейка 50*50, 45*45 d прутка 1,6-1,8 мм;",
          "расстояние между столбами 3 м;",
        ],
        height: "1,5 м.",
        frame: "СТАЛЬ",
        price: "от 2000р./м.п.",
      },

      {
        components: [
          "столбы (стальные) 40*40*2400мм;",
          "заглушка 40*40 ПВХ черная;",
          "сетка Рабица (сталь) ячейка 50*50, 45*45 d прутка 1,6-1,8 мм;",
          "расстояние между столбами 3 м;",
        ],
        height: "2,0 м.",
        frame: "СТАЛЬ",
        price: "от 2400  р./м.п.",
      },
    ],
  },
  //данные для таблицы калитка

  kalitkaData: {
    title: "Калитка распашная сетка рабица",
    rows: [
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 8000 р./м.п.",
      },
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "профлист С8 - 1 лист.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 8000 р./м.п.",
      },
    ],
  },

  //данные для таблицы ворота

  vorotaData: {
    title: "Ворота распашные сетка рабица",
    rows: [
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 12000 р./м.п.",
      },
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 12000 р./м.п.",
      },
    ],
  },
  slideImages : [
    {
      image: rb1,
     alt: "изображение наших работ забора из сетки рабица"
    },
    {
      image: rb2,
      alt: "изображение наших работ забора из сетки рабица"
  
    },
    {
      image: rb3,
      alt: "изображение наших работ забора из сетки рабица"
  
    },
    {
      image: rb4,
      alt: "изображение наших работ забора из сетки рабица"
  
    },

  ],
};

const evro = {
  title: "Забор из евроштакетника ПОД КЛЮЧ",
  image: main5,
  alt: "Забор из евроштакетника  под ключ",
  subtitle: "В стоимость включен: весь материал, монтаж.",
  text: "Заборы из евроштакетника - набор металлических планок, равномерно закрепленных по периметру конструкции как вплотную к друг другу, так и с зазорами. Это современный аналог деревянного штакетника. Получают евроштакетник из листовой оцинкованной стали. Листы режут на полосы, покрывают защитными средствами, обрабатывают края и красят. Результат — планки требуемой высоты, ширины. Средняя высота — 1 м-1,8 м, толщина стали — от 0,4 до 0,55 мм.",
  titlePlus: "Преимущества",
  montageTitle: "Монтаж забора из евроштакетника",
  montageText:
    "Кажется, что установка забора – дело не сложное. Но на самом деле, когда доходит дело до практики, появляются некоторые сложности, с которыми знаком только профессионал. Условно весь монтаж можно разделить на три части: закладывание основания , сборку каркаса, крепление штакетникаКажется, что установка забора – дело не сложное. Но на самом деле, когда доходит дело до практики, появляются некоторые сложности, с которыми знаком только профессионал. Условно весь монтаж можно разделить на три части: закладывание основания , сборку каркаса, крепление штакетника",
  montageImg: montageEvro,
  altShema: "схема установки забора",
  dopComplect: 'Стоимость забора из евроштакетника зависит от: высоты, типа профиля, ширины штакетин, способа установки столбов, типа покрытия.',

  advan: [
    {
      image: plus,
      text: "Плюсы: Обеспечена хорошая циркуляция воздуха",
    },
    {
      image: plus,
      text: "Нет необходимости резать металл. С помощью раздельных секций легко регулировать длину каждого пролета. Риск повредить защитную эмаль и краску сведен к нулю.",
    },
    {
      image: plus,
      text: "Упрощенная сборка облегчит задачу и ускорит время монтажа",
    },
    {
      image: plus,
      text: "Подобное ограждение смотрится необычно и привлекает внимание не только соседей, но и случайных прохожих",
    },
    {
      image: plus,
      text: "Легко менять отдельные части забора",
    },
  ],

  //данные для таблицы забора

  fenceData: {
    title: "ЗАБОР Евроштакетник - СТАНДАРТ",
    rows: [
      {
        components: [
          "Столбы (полимерные) 60*60*2400 мм ",
          "каркас из профильной трубы 40*20",
          "штакетник металлический 0,45 мм",
          "заглушки ПВХ с саморезами;",
          "в комплекте штакетник – шаг 6 см",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 3600р./м.п.",
      },
      {
        components: [
          "Столбы (полимерные) 60*60*2400 мм ",
          "каркас из профильной трубы 40*20",
          "штакетник металлический 0,45 мм",
          "заглушки ПВХ с саморезами;",
          "в комплекте штакетник – шаг 6 см",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 3900 р./м.п.",
      },
    ],
    additionalOptions: [
      "Каркас забора «Шахматка» от 5800 р/м.п.",
      "Бордюрный фундамент от 1700 р/м.п. (высота 20 см, ширина 8 см)",
      "Ленточный фундамент от 2500 р/м.п.  (высота 10 см, ширина 20 см)",
      "Демонтаж старого забора 200 р/м.п.",
      "Ворота откатные от 98 000 р.",
      "Штакетник возможен любой высоты. Расчет – индивидуально.",
    ],
  },
  //варианты заборов во вкладке профлист

  variants: {
    title: "Виды забора из Евроштакетника",
    cards: [
      {
        image: prof5,
        alt: "калитка",
      },
      {
        image: prof6,
        alt: "Ворота распашные",
      },
      {
        image: prof7,
        alt: "Ворота откатные",
      },
    ],
  },
  lentoch: {
    title: "ЗАБОР НА ЛЕНТОЧНОМ ФУНДАМЕНТЕ",
    cards: [
      {
        img: plus,
        title: "ЗАЩИТА ОТ ВЫМЫВАНИЯ ГРУНТА",
        text: "Ленточный фундамент забора из металлического штакетника защищает участок от затопления талыми водами, а также от вымывания грунта с участка дождевой и талой водой."
      },
      {
        img: plus,
        title: "ПЛАНИРОВАНИЕ ЛАНДШАФТА",
        text: "Ленточный фундамент для забора из ероштакетника необходим при дальнейшей подсыпке грунта, в случае, если Вы планируете изменения в ландшафте Вашего участка."
      },
      {
        img: plus,
        title: "ПРОЧНОСТЬ И ДОЛГОВЕЧНОСТЬ",
        text: "Заборы из металлического штакетника на ленточном фундаменте обладают отличной стойкостью к любым внешним воздействиям агрессивной среды и выполняет свою функцию десятки лет."
      },
      {
        img: plus,
        title: "ЭСТЕТИЧНОСТЬ",
        text: "Сам дизайн забора из металлического штакетника на ленточном фундаменте придает забору очень благородный вид. Такой забор облагораживает участок и подчеркивает хороший вкус у его владельца."
      }
    ]
  },

  //данные для таблицы калитка

  kalitkaData: {
    title: "Калитка распашная из металлического штакетника",
    rows: [
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 8000 р./м.п.",
      },
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 8000 р./м.п.",
      },
    ],
  },

  //данные для таблицы ворота

  vorotaData: {
    title: "Ворота распашные из металлического штакетника",
    rows: [
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 12000 р./м.п.",
      },
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 12000 р./м.п.",
      },
    ],
  },
// фото во вкладках виды заборов
slideImages : [
  {
    image: evro1,
   alt: "изображение наших работ забора из евроштакетника"
  },
  {
    image: evro2,
    alt: "изображение наших работ забора из евроштакетника"

  },
  {
    image: evro3,
    alt: "изображение наших работ забора из евроштакетника"

  },
  {
    image: evro4,
    alt: "изображение наших работ забора из евроштакетника"

  },
  {
    image: evro5,
    alt: "изображение наших работ забора из евроштакетника"

  },
  {
    image: evro6,
    alt: "изображение наших работ забора из евроштакетника"

  },
],

};

const wood = {
  title: "Забор из деревянного штакетника под ключ",
  image: main4,
  alt: "Забор из деревянного штакетника под ключ",
  subtitle: "В стоимость включен: весь материал, монтаж.",
  text: "Забор из деревянного штакетника  является самым популярным видом ограждения и используется уже не одну сотню лет. Деревянный штакетник представляет собой узкую по толщине доску различной ширины и высоты. Популярность деревянных заборов из штакетника прежде всего обусловлена простотой и удобством ухода, возможностью изготовления штакетин любых размеров и сечений, внесения изменений в готовую конструкцию на любом этапе эксплуатации.",
  titlePlus: "Преимущества",
  montageTitle: "Монтаж забора из деревянного штакетника",
  montageText: "Предлагаем услугу по установке деревянного забора со штакетником на вашем участке. Это простое и надежное ограждение, которое придаст вашей территории аккуратный и ухоженный вид. Начнем работы с фиксации прочных опорных столбов в бетоне. Тщательно выровняем их по линии, создавая ровную основу для всей конструкции. Далее приступим к монтажу горизонтальных реек-планок. Мы выверим их расположение, добиваясь идеальной ровности рядов. Завершающим этапом станет крепление вертикальных штакетин. Расположим их с равномерными интервалами, создавая гармоничную ритмичную композицию. Деревянный забор со штакетником - классическое решение, которое отлично впишется в ландшафт вашего загородного участка. Мы выполним все работы качественно и в срок, подарив вашей территории аккуратное и надежное ограждение.",
  montageImg: montageWood,
  altShema: "схема установки забора",
  advan: [
    {
      image: plus,
      text: "Плюсы:  представляет собой достаточно легкую и не требующую фундамента конструкцию",
    },
    {
      image: plus,
      text: "Древесина — натуральный и экологически чистый строительный материал",
    },
    {
      image: plus,
      text: "Дерево прекрасно сочетается с другими стройматериалами (например, с камнем), а также с ландшафтом участка и дизайном дома",
    },
    {
      image: plus,
      text: "Ограждения данного типа достаточно легко ремонтируются: при необходимости можно заменить лишь отдельные элементы в секциях.",
    },
    {
      image: plus,
      text: "Ограждение, доски в котором набиты с просветом, значительно уменьшает скорость ветра.",
    },
    {
      image: plus,
      text: "Вариативность исполнения: при желании штакетник можно прибивать не только встык или с интервалами, но и в шахматном порядке.",
    },
    {
      image: plus,
      text: "Помимо природной красоты, хорошо обработанные доски (в данном случае речь идет о древесине хвойных пород), пока они свежие, дают очень приятный и полезный аромат смолы.",
    },
  ],
  fenceData: {
    title: "Забор из деревянного штакетника пролетами ",
    rows: [
      {
        components: [
          "столбы (стальные) 40*40*2400мм (толщина металла 2 мм;);",
          "прожилина деревянная 25х100 мм;",
          "пролет деревянный 3 м;",
          "cаморезы;",
          "заглушки ПВХ;",
          "штакетник (сосна) 20х100мм – шаг 6 см;",
        ],
        height: "1,5 м.",
        frame: "Дерево",
        price: "от 2700р./м.п.",
      },
      {
        components: [
          "столбы (стальные) 40*40*2400мм (толщина металла 2 мм;);",
          "заглушка 60*60 ПВХ черная;;",
          "прожилины (полимерные) 40*20 мм (толщина металла 1,5 мм) – 2 шт.;",
          "cаморезы;",
          "заглушки ПВХ;",
          "штакетник (сосна) 20х100мм – шаг 6 см;",
        ],
        height: "1,5 м.",
        frame: "Металл",
        price: "от 3600р./м.п.",
      },
      {
        components: [
          "столбы (стальные) 40*40*2400мм (толщина металла 2 мм;);",
          "заглушка 60*60 ПВХ черная;;",
          "прожилины (полимерные) 40*20 мм (толщина металла 1,5 мм) – 2 шт.;",
          "cаморезы;",
          "заглушки ПВХ;",
          "штакетник (сосна) 20х100мм – шаг 6 см;",
        ],
        height: "2 м.",
        frame: "Металл",
        price: "от 3900р./м.п.",
      },
      
    ],
  },
  //данные для таблицы калитка

  kalitkaData: {
    title: "Калитка распашная из металлического штакетника",
    rows: [
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 8000 р./м.п.",
      },
      {
        components: [
          "В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ. Накладные петли - 2 шт.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 8000 р./м.п.",
      },
    ],
  },

  //данные для таблицы ворота

  vorotaData: {
    title: "Ворота распашные из металлического штакетника",
    rows: [
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "1,5 м.",
        frame: "Сталь",
        price: "от 12000 р./м.п.",
      },
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 12000 р./м.п.",
      },
    ],
  },
  slideImages : [
    {
      image: wd1,
     alt: "изображение наших работ забора из деревянного штакетника"
    },
    {
      image: wd2,
      alt: "изображение наших работ забора из деревянного штакетника"
  
    },
    {
      image: wd3,
      alt: "изображение наших работ забора из деревянного штакетника"
  
    },
    {
      image: wd4,
      alt: "изображение наших работ забора из деревянного штакетника"
  
    },
    {
      image: wd5,
      alt: "изображение наших работ забора из деревянного штакетника"
  
    },
    

  ],
};

const set3d = {
  title: "3абор из 3D-сетки",
  image: main3,
  alt: "3абор из 3D-сетки под ключ",
  subtitle: "В стоимость включен: весь материал, монтаж.",
  text: "Забор из 3D-сетки представляет собой современное и привлекательное решение для ограждения вашей территории. Он состоит из прочной сварной сетки, изготовленной из стальной проволоки, которая формирует объемную трехмерную структуру. Мы будем рады обсудить с вами более подробные характеристики и варианты исполнения данного типа ограждения, чтобы подобрать оптимальное решение для ваших потребностей. Пожалуйста, не стесняйтесь обращаться к нам с дополнительными вопросами.",
  titlePlus: "Преимущества",
  montageTitle: "Монтаж забора из 3абор из 3D-сетки",
  montageText: "Монтаж забора из 3D-сетки: Подготовка основания: Необходимо выровнять и утрамбовать поверхность, на которой будет установлен забор. Установка столбов: На подготовленное основание устанавливаются металлические опорные столбы, которые будут поддерживать конструкцию забора. Столбы размещаются на равном расстоянии друг от друга.  Крепление панелей: Секции 3D-сетки соединяются между собой и прикрепляются к установленным столбам с помощью специальных крепежных элементов. Это обеспечивает надежную и ровную конструкцию забора. Регулировка натяжения: Сетчатые панели аккуратно натягиваются и фиксируются для придания забору необходимой жесткости и устойчивости. Финальная обработка: По завершении монтажа проводится финальная проверка и при необходимости дополнительная подгонка отдельных элементов. Весь процесс установки забора из 3D-сетки, как правило, занимает немного времени и не требует привлечения большого количества рабочих. Наши специалисты могут оказать консультационную и практическую помощь на всех этапах монтажа..",
  montageImg: montage3d,
  altShema: "схема установки забора",
  advan: [
    {
      image: plus,
      text: "Плюсы:  Высокая прочность и надежность: Забор выдерживает значительные нагрузки и устойчив к механическим воздействиям, обеспечивая надежную защиту участка.",
    },
    {
      image: plus,
      text: "Эстетичный внешний вид: Трехмерная структура сетки создает оригинальный и современный дизайн, который гармонично впишется в любой ландшафт.",
    },
    {
      image: plus,
      text: "Долговечность: Материалы, используемые в производстве, устойчивы к погодным условиям, коррозии и ультрафиолетовому излучению, что обеспечивает длительный срок службы забора.",
    },
    {
      image: plus,
      text: "Простота установки: Модульная конструкция позволяет быстро и легко монтировать забор, а также при необходимости осуществлять его демонтаж и перенос.",
    },

  ],
  fenceData: {
    title: "Забор из 3D-сетки",
    rows: [
      {
        components: [
          " столбы RAL 60*40*2400мм/60*60*2400;",
          "заглушка 600*40/60*60 ПВХ черная;",
          "3D-сетка RAL ячейка 50*200, d прутка 3.5/4/5/6 мм;",
          "расстояние между столбами 2,5 м.",
        ],
        height: "1,53 м.",
        frame: "Сталь",
        price: "от 3500р./м.п.",
      },
      {
        components: [
          " столбы RAL 60*40*2400мм/60*60*2400;",
          "заглушка 600*40/60*60 ПВХ черная;",
          "3D-сетка RAL ячейка 50*200, d прутка 3.5/4/5/6 мм;",
          "расстояние между столбами 2,5 м.",
        ],
        height: "1,73 м.",
        frame: "Сталь",
        price: "от 3700р./м.п.",
      },
      {
        components: [
          " столбы RAL 60*40*2400мм/60*60*2400;",
          "заглушка 600*40/60*60 ПВХ черная;",
          "3D-сетка RAL ячейка 50*200, d прутка 3.5/4/5/6 мм;",
          "расстояние между столбами 2,5 м.",
        ],
        height: "2,3 м.",
        frame: "Сталь",
        price: "от 3850р./м.п.",
      },
      
    ],
  },
  //данные для таблицы калитка

  kalitkaData: {
    title: "Калитка распашная из 3D-сетки",
    rows: [
      {
        components: [
          "В комплект входит:",
          "столбы RAL 60*40*2400мм/60*60*2400;",
          "заглушка 600*40/60*60 ПВХ черная",
          "3Д сетка RAL ячейка 50*200, d прутка 3.5/4/5/6 мм.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "1,53 м.",
        frame: "Сталь",
        price: "от 10000 р./м.п.",
      },
      {
        components: [
          "В комплект входит:",
          "столбы RAL 60*40*2400мм/60*60*2400;",
          "заглушка 600*40/60*60 ПВХ черная",
          "3Д сетка RAL ячейка 50*200, d прутка 3.5/4/5/6 мм.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "1,73 м.",
        frame: "Сталь",
        price: "от 10000 р./м.п.",
      },
      {
        components: [
          "В комплект входит:",
          "столбы RAL 60*40*2400мм/60*60*2400;",
          "заглушка 600*40/60*60 ПВХ черная",
          "3Д сетка RAL ячейка 50*200, d прутка 3.5/4/5/6 мм.",
          "Замок с ручкой -1 шт.",
          "Накладные петли - 2 шт.",
        ],
        height: "2 м.",
        frame: "Сталь",
        price: "от 10000 р./м.п.",
      },

    ],
  },

  //данные для таблицы ворота

  vorotaData: {
    title: "Ворота распашные из 3D-сетки",
    rows: [
      {
        components: [
          " В комплект входит:",
          "столбы 60*60",
          "каркас из профильной трубы 40*20",
          "заглушки ПВХ",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "1,53 м.",
        frame: "Сталь",
        price: "от 20000 р./м.п.",
      },
      {
        components: [
          " В комплект входит:",
          "столбы 60*60/80*80",
          "заглушки ПВХ, хомуты",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "1,73 м.",
        frame: "Сталь",
        price: "от 20000 р./м.п.",
      },
      {
        components: [
          " В комплект входит:",
          "столбы 60*60/80*80",
          "заглушки ПВХ, хомуты",
          "Нижний фиксатор ворот - 2 шт.",
          "Горизонтальная задвижка - 1 шт.",
          "Шарниры гаражные - 4 шт.",
          "Проушины под навесной замок.",
        ],
        height: "2,0 м.",
        frame: "Сталь",
        price: "от 20000 р./м.п.",
      },
    ],
  },
  slideImages : [
    {
      image: s3d1,
     alt: "изображение наших работ забора из 3D-сетки"
    },
    {
      image: s3d2,
      alt: "изображение наших работ забора из 3D-сетки"
  
    },
    {
      image: s3d3,
      alt: "изображение наших работ забора из 3D-сетки"
  
    },
    {
      image: s3d4,
      alt: "изображение наших работ забора из 3D-сетки"
  
    },
    {
      image: s3d5,
      alt: "изображение наших работ забора из 3D-сетки"
  
    },
    {
      image: s3d6,
      alt: "изображение наших работ забора из 3D-сетки"
  
    },
    

  ],
};
const karkas = {
  title: "Каркас забора под ключ",
  image: ks1,
  alt: "Каркас забора под ключ",
  subtitle: "В стоимость включен: весь материал, монтаж.",
  text: "Каркас забора – это совокупность элементов, на которые крепят облицовочный материал (штакетник, металлический сайдинг или профлист). Без каркаса забор не сможет выполнять свою роль как задумано и защищать ваш участок. Вне зависимости от того, какой материал вы купите для своего ограждения — профнастил, штакетник или сайдинг — вам в любом случае пригодится каркас, чтобы правильно смонтировать ограждение и создать необходимый внешний вид.",
  titlePlus: "Преимущества",
  montageTitle: "Монтаж Каркаса под ключ",
  montageText: "Каркас забора под ключ. Подготовка основания: Необходимо выровнять и утрамбовать поверхность, на которой будет установлен забор. Установка столбов: На подготовленное основание устанавливаются металлические опорные столбы, которые будут поддерживать конструкцию забора. Столбы размещаются на равном расстоянии друг от друга. Наши специалисты могут оказать консультационную и практическую помощь на всех этапах монтажа..",
  montageImg: montage3d,
  altShema: "схема установки забора",
  advan: [
    {
      image: plus,
      text: "Плюсы: Коррозионная стойкость. Каркас забора после установки покрывается трехслойной полимерной краской, что позволяет контролировать технологические процессы. Поэтому изделие отлично защищено от коррозии и служит долгое время.",
    },
    {
      image: plus,
      text: "Надёжность. Детали ворот, труб, калиток и направляющих крепят друг к другу путём сварки.",
    },
    {
      image: plus,
      text: "Экономия средств. Металлические составляющие для каркаса не нужно будет красить или вдобавок обрабатывать защитными составами, в отличие от деревянных аналогов.",
    },
  

  ],
  fenceData: {
    title: "Каркас забора",
    rows: [
      {
        components: [
          "столбы RAL 60*40*2400мм(толщина металла 2 мм;);",
          "заглушка 600*40/60*60 ПВХ черная;",
          "прожилины (полимерные) 40*20 мм (толщина металла 1,5 мм) – 2 шт.;",
        ],
        height: "1,5 м.",
        frame: "Полимер",
        price: "от 2300р./м.п.",
      },
      {
        components: [
          "столбы RAL 60*40*3000мм(толщина металла 2 мм;);",
          "заглушка 600*40/60*60 ПВХ черная;",
          "прожилины (полимерные) 40*20 мм (толщина металла 1,5 мм) – 2 шт.;",
        ],
        height: "2 м.",
        frame: "Полимер",
        price: "от 2500р./м.п.",
      },
    ],
  },

  slideImages : [
    {
      image: ks1,
     alt: "изображение наших работ забора Каркас забора"
    },
    {
      image: ks2,
      alt: "изображение наших работ забора Каркас забора"
  
    },
    {
      image: ks3,
      alt: "изображение наших работ забора Каркас забора"
  
    },
    {
      image: ks4,
 alt: "изображение наших работ забора Каркас забора"
  
    },
    {
      image: ks5,
    alt: "изображение наших работ забора Каркас забора"
  
    },

  ],
};



export { zaborImages, proflist, rabitsa, evro, wood, set3d, karkas };
